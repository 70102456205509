(function () {
  const env = "prod";
  const graphDomain = env == "prod" ? "stent.io" : (env == "staging" || env == "dev") ? "staging.stent.io" : "dev.stent.io:5000";
  const apiDomain = env == "prod" ? "stent.io" : (env == "staging" || env == "dev") ? "staging.stent.io" : "dev.stent.io:5010";
  const authDomain = (env == "prod") ? "stent.io" : (env == "staging" || env == "dev") ? "staging.stent.io" : "dev.stent.io:5050";
  const protocol = (env == "prod" || env == "staging" || env == "dev") ? "https://" : "http://";
  const useFaker = false;

  let locale = "en-US";
  if (window.navigator && window.navigator.languages && window.navigator.languages.length > 0) {
    locale = window.navigator.languages[0];
  }

  window["stent"] = {
    version: {
      release: "1.2024.36",
      build: "20240908194604"
    },
    locale: locale,
    log: env == "prod" ? false : true,
    authentication: {
      client: "Eg3icS4Vj1r6zr4rDFenOh4BOV6YBEzlCstKbDIAKisAMCL1GGZBRuJ5+0E27nzH",
      supportBearer: protocol + "auth." + authDomain + "/zendesk/chat/auth",
      loginUrl: protocol + "auth." + authDomain + "/login"
    },
    api: {
      graphQL: protocol + "graph." + graphDomain + "/graphql",
      rest: protocol + "graph." + graphDomain + "/rest/v1",
      api: (useFaker && env === "dev") ? "http://localhost:9002/graphql" : protocol + "api." + apiDomain,
      auth: protocol + "auth." + authDomain
    },
    tenant: {
      key: "",
      name: "",
      timezone: ""
    },
    identity: {
      lastName: "",
      firstName: "",
      email: "",
      id: ""
    },
    env: (env == "prod" || env == "staging") ? "prod" : "dev",
    breakpoints: {
      sentimentAnalysis: 0.5
    }
  };
})();

stent.libs = {
  navbar: {
    prod: {
      files: ["/assets/js/requires/stent.navbar.min.js"]
    },
    dev: {
      files: ["/assets/js/requires/stent.navbar.js"]
    }
  },
  widgets: {
    prod: {
      files: ["/assets/js/requires/stent.widgets.min.js"]
    },
    dev: {
      files: ["/assets/js/requires/stent.widgets.js"]
    }
  },
  quill: {
    prod: {
      files: ["/node_modules/quill/dist/quill.min.js", "/assets/js/requires/stent.quill.min.js"]
    },
    dev: {
      files: ["/node_modules/quill/dist/quill.min.js", "/assets/js/requires/stent.quill.js"]
    }
  },
  flatpickr: {
    prod: {
      files: ["/node_modules/flatpickr/dist/flatpickr.min.js", "/assets/js/requires/stent.flatpickr.min.js"]
    },
    dev: {
      files: ["/node_modules/flatpickr/dist/flatpickr.js", "/assets/js/requires/stent.flatpickr.js"]
    }
  },
  dropzone: {
    prod: {
      files: ["/node_modules/dropzone/dist/min/dropzone.min.js", "/assets/js/requires/stent.dropzone.min.js"]
    },
    dev: {
      files: ["/node_modules/dropzone/dist/min/dropzone.min.js", "/assets/js/requires/stent.dropzone.js"]
    }
  },
  popover: {
    prod: {
      files: ["/assets/js/requires/stent.popover.min.js"]
    },
    dev: {
      files: ["/assets/js/requires/stent.popover.js"]
    }
  },
  highlight: {
    prod: {
      files: ["/node_modules/highlightjs/highlight.pack.min.js", "/assets/js/requires/stent.highlight.min.js"]
    },
    dev: {
      files: ["/node_modules/highlightjs/highlight.pack.min.js", "/assets/js/requires/stent.highlight.js"]
    }
  },
  list: {
    prod: {
      files: ["/node_modules/list.js/dist/list.min.js", "/assets/js/requires/stent.list.min.js"]
    },
    dev: {
      files: ["/node_modules/list.js/dist/list.min.js", "/assets/js/requires/stent.list.js"]
    }
  },
  ace: {
    prod: {
      files: [
        "/node_modules/ace-builds/src-min/ace.js",
        "/node_modules/ace-builds/src-min/mode-javascript.js",
        "/node_modules/ace-builds/src-min/mode-aql.js",
        "/node_modules/ace-builds/src-min/theme-twilight.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/ace-builds/src/ace.js",
        "/node_modules/ace-builds/src/mode-javascript.js",
        "/node_modules/ace-builds/src/mode-aql.js",
        "/node_modules/ace-builds/src/theme-tomorrow.js"
      ]
    }
  },
  chart: {
    prod: {
      files: [
        "/node_modules/chart.js/dist/Chart.min.js",
        "/assets/libs/chart.js/Chart.extension.js",
        "/assets/js/requires/stent.chart.min.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/chart.js/dist/Chart.min.js",
        "/assets/libs/chart.js/Chart.extension.js",
        "/assets/js/requires/stent.chart.js"
      ]
    }
  },
  fullcalendar: {
    prod: {
      files: [
        "/node_modules/@fullcalendar/core/main.min.js",
        "/node_modules/@fullcalendar/interaction/main.min.js",
        "/node_modules/@fullcalendar/bootstrap/main.min.js",
        "/node_modules/@fullcalendar/daygrid/main.min.js",
        "/node_modules/@fullcalendar/timegrid/main.min.js",
        "/node_modules/@fullcalendar/list/main.min.js",
        "/node_modules/@fullcalendar/timeline/main.min.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/@fullcalendar/core/main.js",
        "/node_modules/@fullcalendar/interaction/main.js",
        "/node_modules/@fullcalendar/bootstrap/main.js",
        "/node_modules/@fullcalendar/daygrid/main.js",
        "/node_modules/@fullcalendar/timegrid/main.js",
        "/node_modules/@fullcalendar/list/main.js",
        "/node_modules/@fullcalendar/timeline/main.js"
      ]
    }
  },
  draggable: {
    prod: {
      files: [
        "/node_modules/draggabilly/dist/draggabilly.pkgd.min.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/draggabilly/dist/draggabilly.pkgd.min.js"
      ]
    }
  },
  slider: {
    prod: {
      files: [
        "/node_modules/nouislider/distribute/nouislider.min.js"
      ]
    },
    dev: {
      files: [
        "/node_modules/nouislider/distribute/nouislider.min.js"
      ]
    }
  },
  qr: {
    prod: {
      files: ["/assets/js/requires/stent.qr.min.js"]
    },
    dev: {
      files: ["/assets/js/requires/stent.qr.js"]
    }
  }
};

stent.loadedLibraries = [];

stent.requireJS = function(libsName, callback) {
  // Check if libsName is an array or a string
  if (!(libsName != null && (typeof libsName == "string" || typeof libsName == "object"))) return false;

  // Check if libsName is a string. If yes, transform it to an array
  var libs = typeof libsName == "string" ? [libsName] : libsName;

  var loadScript = function(libName, scriptURL) {

    var script = document.createElement("script");
    script.type = "application/javascript";
    script.src = scriptURL;
    document.getElementsByTagName("head")[0].appendChild(script);

    // Important success and error for the promise
    script.onload = function() {
      onLoaded(libName, scriptURL);
    };
    script.onerror = function() {};

  };

  var initLibs = function() {
    for (var i = 0; i < libs.length; i++) {
      var lib = stent[libs[i]];
      if (lib && lib.init) {
        lib.init();
      }
    }
  };

  // Build an array of files URL to load
  var filesToLoad = [];

  for (var i = 0; i < libs.length; i++) {
    var libName = libs[i];
    if (stent.libs[libName] && stent.libs[libName][stent.env].files && stent.loadedLibraries.indexOf(libName) === -1) {
      for (var j = 0; j < stent.libs[libName][stent.env].files.length; j++) {
        var scriptURL = stent.libs[libName][stent.env].files[j];
        filesToLoad.push({ libName: libName, scriptURL: scriptURL });
      }
    }
  }

  var index = 0;
  if (filesToLoad.length > 0) {
    loadScript(filesToLoad[0].libName, filesToLoad[0].scriptURL + "?v=" + stent.version.release + "." + stent.version.build);
  } else {
    initLibs();
    if (typeof callback === "function") {
      callback();
    }
  }

  var onLoaded = function(libName) {

    index++;
    stent.loadedLibraries.push(libName);

    if (index < filesToLoad.length) {
      loadScript(filesToLoad[index].libName, filesToLoad[index].scriptURL + "?v=" + stent.version.release + "." + stent.version.build);
    } else {
      initLibs();
      if (typeof callback === "function") {
        callback();
      }
    }
  };
};

stent.utils = {
  guid: function() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  /* eslint-disable */
  isValidURL: function (url) {
    var expression = /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi;
    var regex = new RegExp(expression);
    return !(url.match(regex) === null);
  },
  /* eslint-enable */

  /* eslint-disable */
  isEmailValid: function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  /* eslint-enable */

  /* eslint-disable */
  escapeJsonString: function (str) {
    if (!str) {
      return str;
    }
    return str
      .replace(/[\\]/g, "\\\\")
      .replace(/[\"]/g, "\\\"")
      .replace(/[\/]/g, "\\/")
      .replace(/[\b]/g, "\\b")
      .replace(/[\f]/g, "\\f")
      .replace(/[\n]/g, "\\n")
      .replace(/[\r]/g, "\\r")
      .replace(/[\t]/g, "\\t");
  },
  /* eslint-enable */

  randomIntFromInterval: function(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  },

  log: function(message) {
    if (stent.log) console.log(message);
  },

  arrayToObject: (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {}),

  getURLParam: function(paramName) {
    if (paramName) {
      return new URL(window.location.href).searchParams.get(paramName);
    }
    return null;
  },

  getURLRemovedParam(paramName) {
    var rtn = window.location.href.split("?")[0],
      param,
      params_arr = [],
      queryString = window.location.href.indexOf("?") !== -1 ? window.location.href.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === paramName) {
          params_arr.splice(i, 1);
        }
      }
      rtn = rtn + (params_arr.length > 0 ? "?" : "") + params_arr.join("&");
    }
    return rtn;
  },

  getURLWithUpdatedParam: function(paramName, val) {
    var TheAnchor = null;
    var newAdditionalURL = "";
    var tempArray = window.location.href.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";

    if (additionalURL) {
      let tmpAnchor = additionalURL.split("#");
      let TheParams = tmpAnchor[0];
      TheAnchor = tmpAnchor[1];
      if (TheAnchor) additionalURL = TheParams;

      tempArray = additionalURL.split("&");

      for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i].split("=")[0] != paramName) {
          newAdditionalURL += temp + tempArray[i];
          temp = "&";
        }
      }
    } else {
      let tmpAnchor = baseURL.split("#");
      let TheParams = tmpAnchor[0];
      TheAnchor = tmpAnchor[1];

      if (TheParams) baseURL = TheParams;
    }

    if (TheAnchor) val += "#" + TheAnchor;

    let rows_txt = temp + "" + paramName + "=" + val;
    return baseURL + "?" + newAdditionalURL + rows_txt;
  },

  parseJwt: function(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  },

  getCookie: function(name) {
    var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
  },

  humanFileSize: function(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }
    var units = si
      ? ["kB","MB","GB","TB","PB","EB","ZB","YB"]
      : ["KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+" "+units[u];
  },

  generateInitialsLogo: function (name) {
    let initials = "..";
    let splitName = name.split(" ");
    if (splitName.length > 1) {
      initials = splitName[0].substring(0, 1).toUpperCase() + splitName[1].substring(0, 1).toUpperCase();
    } else if (name.length >= 2) {
      initials = name.substring(0, 2).toUpperCase();
    }
    return `<span title="${name}" style="background-color: ${stent.utils.stringToColour(initials)};" class="initials">${initials}</span>`;
  },

  stringToColour: function(input_str) {

    var baseRed = 128;
    var baseGreen = 128;
    var baseBlue = 128;

    //lazy seeded random hack to get values from 0 - 256
    //for seed just take bitwise XOR of first two chars
    var seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1);
    var rand_1 = Math.abs((Math.sin(seed++) * 10000)) % 256;
    var rand_2 = Math.abs((Math.sin(seed++) * 10000)) % 256;
    var rand_3 = Math.abs((Math.sin(seed++) * 10000)) % 256;

    //build colour
    var red = Math.round((rand_1 + baseRed) / 2);
    var green = Math.round((rand_2 + baseGreen) / 2);
    var blue = Math.round((rand_3 + baseBlue) / 2);

    //return { red: red, green: green, blue: blue };
    return `rgba(${red}, ${green}, ${blue}, 1)`;
  },

  popupCenter: function ({url, title, w, h, onClose}) {

    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (typeof onClose === "function") {
      var timer = setInterval(function() {
        if (newWindow.closed) {
          clearInterval(timer);
          onClose();
        }
      }, 1000);
    }

    if (window.focus) newWindow.focus();

  },

  arrayToHMLList: function (arr) {

    if (!arr) {
      return "";
    }

    let html = "";
    html += "<ul style='text-align: left; margin: 5px 0 0 0; padding: 0 15px;'>";
    arr.forEach((entry) => {
      html += "<li>" + entry + "</li>";
    });
    html += "</ul>";

    return html;
  },

  sortArrayOfObjectByPropertyName: function (property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a,b) {
      /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  },

  capitalize: (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

};
stent.auth = (function () {
  let _roles = [];
  let _wildcard = false;

  var _hostname = window.location.hostname.split(".");
  var _cookiePrefix = _hostname.length > 3 ? _hostname[1] + "." :  "";

  _hostname.shift();
  _hostname = "." + _hostname.join(".");

  const getHostname = function () {
    return _hostname;
  };

  const isWilcard = function () {
    return _wildcard;
  };

  const getBearer = function () {
    return stent.utils.getCookie(`${_cookiePrefix}stnt.idtoken`);
  };

  const getRefreshToken = function () {
    return stent.utils.getCookie(`${_cookiePrefix}stnt.refreshtoken`);
  };

  const getUserIdentityKey = function () {
    if (
      stent.user &&
      stent.user.identities &&
      stent.user.identities["http://schemas.stent.io/identity/key"] &&
      Array.isArray(stent.user.identities["http://schemas.stent.io/identity/key"]) &&
      stent.user.identities["http://schemas.stent.io/identity/key"].length > 0
    ) {
      return stent.user.identities["http://schemas.stent.io/identity/key"][0];
    }
    return null;
  };

  const doRefreshToken = async function () {
    return await stent.ajax.postAuthAsync("/passwordless/refresh", {
      refresh_token: getRefreshToken(),
      client_id: stent.authentication.client,
    });
  };

  const getUserProfile = async () => {
    const url = stent.api.api;
    const method = "POST";
    let bearer = "Bearer " + getBearer();
    const contentType = "application/json";

    const query = {
      query: `query {
        userContext {
          user {
            id
            email
            firstName
            lastName
            pictureUrl
          }
        }
      }`,
    };

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": contentType,
          Authorization: bearer,
        },
        body: JSON.stringify(query),
      });

      if (response.status === 200) {
        let jsonResponse = await response.json();
        let out = {
          ok: true,
          message: jsonResponse,
        };
        return out;
      } else {
        let errorMessage = await response.text();
        let out = {
          ok: false,
          error: {
            status: response.status,
            method: "getUserProfile",
            message: errorMessage,
          },
        };
        return out;
      }
    } catch (errorMessage) {
      let out = {
        ok: false,
        error: {
          status: "N/A",
          method: "getUserProfile",
          message: errorMessage,
        },
      };
      return out;
    }
  };

  const cleanCookiesAndLocalStorage = function () {

    localStorage.removeItem(`${_cookiePrefix}stnt.idtoken`);
    localStorage.removeItem(`${_cookiePrefix}stnt.refreshtoken`);

    var domainName = ".stent.io";
    var cookieNameOld = "aat." + window.location.hostname;
    document.cookie = cookieNameOld + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=" + _hostname;

    var cookieName = `${_cookiePrefix}stnt.idtoken`;
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=" + domainName;

    cookieName = `${_cookiePrefix}stnt.refreshtoken`;
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=" + domainName;
  };

  const isBearerExpired = function () {
    let bearer = getBearer();

    if (!bearer) {
      return false;
    }

    let decodedBearer = stent.utils.parseJwt(bearer);
    let expireTimestamp = decodedBearer.exp ? decodedBearer.exp * 1000 : 0;
    if (new Date().getTime() > expireTimestamp) {
      return true;
    }

    return false;
  };

  const getRoles = function () {
    return _roles;
  };

  var redirectToAuthPage = function () {
    document.location.href =
      stent.authentication.loginUrl +
      "?client=" +
      encodeURIComponent(stent.authentication.client) +
      "&redirectUrl=" +
      window.location.href;
  };

  var getTenantData = async function () {

    /*eslint-disable */
    var query = `
      query {
        workspaceContext {
          workspace {
            features {
              name
              properties
            }
            organization {
              state
            }
            id
            company {
                name
                logoUrl
            }
            locale{
                timezone
            }
          }
        }
      }`
    /*eslint-enable */
    let result = await stent.ajax.getApiAsync(query, "POST");

    if (result.toString().includes("Failed to fetch")) {
      stent.ui.loadError({ fileToLoad: "/errors/500.html" });
      $(".navbar").addClass("d-none");
      $(".main-content").css("margin-left", 0);
      return false;
    }

    if (
      result &&
      result.ok === true &&
      result.message &&
      result.message.data &&
      result.message.data.workspaceContext &&
      result.message.data.workspaceContext.workspace
    ) {

      let workspaceObject = result.message.data.workspaceContext.workspace;
      let tenantObject = {
        key: workspaceObject.id,
        name: workspaceObject.company && workspaceObject.company.name ? workspaceObject.company.name : null,
        logo: workspaceObject.company && workspaceObject.company.logoUrl ? workspaceObject.company.logoUrl : null,
        timezone: workspaceObject.locale && workspaceObject.locale.timezone ? workspaceObject.locale.timezone : null,
        organization: workspaceObject.organization ? workspaceObject.organization : null,
        features: workspaceObject.features ? workspaceObject.features : null
      };

      stent.tenant = {
        ...stent.tenant,
        ...tenantObject,
      };

      stent.utils.log(stent.tenant);
    } else {
      // user is not in a tenant => Redirect to no-tenant error page
      stent.ui.loadError({ fileToLoad: "/errors/no-tenant.html" });
      $(".navbar").addClass("d-none");
      $(".main-content").css("margin-left", 0);
      return false;
    }

    return true;
  };

  var init = async function () {

    let bearer = getBearer();
    let refreshToken = getRefreshToken();

    if (
      (bearer === "null" || bearer === null || bearer === "" || typeof bearer === "undefined") &&
      (refreshToken === "null" || refreshToken === null || refreshToken === "" || typeof refreshToken === "undefined")
    ) {
      cleanCookiesAndLocalStorage();
      redirectToAuthPage();
      return;
    } else {
      // If the bearer is bad AND we have a refresh token
      if (
        (bearer === "null" || bearer === null || bearer === "" || typeof bearer === "undefined" || isBearerExpired()) &&
        refreshToken
      ) {
        // Regenerate the token
        let refreshTokenRequest = await doRefreshToken();

        // Error when try to refresh the token
        if (
          !refreshTokenRequest.ok ||
          !refreshTokenRequest.message ||
          !refreshTokenRequest.message.refresh_token ||
          !refreshTokenRequest.message.access_token
        ) {
          cleanCookiesAndLocalStorage();
          redirectToAuthPage();
          return;
        }

        // IMPORTANT !
        // Cookies are setted the the response Header of the doRefreshToken request

        // Set the variables with the updated values
        bearer = getBearer();
        refreshToken = getRefreshToken();
      }
    }

    // Set tenant key
    stent.tenant.key = document.location.pathname.split("/")[1];

    let jwt = stent.utils.parseJwt(getBearer());

    if (jwt["http://schemas.stent.io/identity/claims/tenants"] === "*") {
      _wildcard = true;
    }

    stent.user = {};

    let fetchUserIdentity = await getUserProfile();

    if (
      fetchUserIdentity.ok === true &&
      fetchUserIdentity.message &&
      fetchUserIdentity.message.data &&
      fetchUserIdentity.message.data.userContext &&
      fetchUserIdentity.message.data.userContext.user
    ) {
      stent.user.id = fetchUserIdentity.message.data.userContext.user.id
        ? fetchUserIdentity.message.data.userContext.user.id
        : null;

      stent.user.email = fetchUserIdentity.message.data.userContext.user.email
        ? fetchUserIdentity.message.data.userContext.user.email
        : null;

      stent.user.firstName = fetchUserIdentity.message.data.userContext.user.firstName
        ? fetchUserIdentity.message.data.userContext.user.firstName
        : null;

      stent.user.lastName = fetchUserIdentity.message.data.userContext.user.lastName
        ? fetchUserIdentity.message.data.userContext.user.lastName
        : null;

      stent.user.pictureUrl = fetchUserIdentity.message.data.userContext.user.pictureUrl
        ? fetchUserIdentity.message.data.userContext.user.pictureUrl
        : null;

      // Manage identities
      stent.user.identities = {};

      // Loop on JWT properties to find identities
      for (let prop in jwt) {
        if (prop.includes("http://schemas.stent.io/identity/")) {
          // Push always an array of identity Keys, not a string if only one identity available
          if (prop === "http://schemas.stent.io/identity/key" && typeof jwt[prop] === "string") {
            stent.user.identities[prop] = [jwt[prop]];
          } else {
            stent.user.identities[prop] = jwt[prop];
          }
        }
      }
    }

    stent.utils.log(stent.user);

    if (window["logInSentry"]) {
      // Sentry user object sent with error
      let sentryUserObject = { ...stent.user };
      delete sentryUserObject.pictureUrl;
      Sentry.setUser(sentryUserObject);
    }

    if (jwt.role && jwt.role === "customer:admin") {
      _roles.push(jwt.role);
    }

    if (stent.tenant.key) {
      return await getTenantData();
    }

    return true;
  };

  return {
    getBearer,
    getRefreshToken,
    getRoles,
    getHostname,
    getUserIdentityKey,
    isBearerExpired,
    redirectToAuthPage,
    cleanCookiesAndLocalStorage,
    isWilcard,
    init,
    refreshToken: doRefreshToken,
  };
})();

stent.ajax = (function () {

  var getGraphQL = function (graphQuery, callback, callbackError, forceUrl) {
    var query = {
      query: graphQuery
    };
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var graphRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(query)
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.graphQL), graphRequest, callback, callbackError);
  };

  var getRest = function (path, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "GET",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default"
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var postRest = function (path, body, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var putRest = function (path, body, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "PUT",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: body ? JSON.stringify(body) : ""
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var patchRest = function (path, body, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "PATCH",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: body ? JSON.stringify(body) : ""
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var deleteRest = function (path, body, callback, callbackError, forceUrl) {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "DELETE",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    processCallbackRequest((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest, callback, callbackError);
  };

  var processCallbackError = function (statusCode, error, callbackError) {
    if (typeof callbackError === "function") {
      callbackError(error);
      return;
    }
    // Set error Message globally
    stent.error = error;
    switch (statusCode) {
      case 401:
        stent.ui.loadError({ fileToLoad: "/errors/401.html" });
        break;
      case 404:
        stent.ui.loadError({ fileToLoad: "/errors/404.html" });
        break;
      case 500:
        stent.ui.loadError({ fileToLoad: "/errors/500.html" });
        break;
      default:
        stent.ui.loadError({ fileToLoad: "/errors/500.html" });
    }
  };

  var processCallbackRequest = async function (path, restApiRequest, callback, callbackError) {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    fetch(path, restApiRequest)
      .then(function (res) {
        switch (res.status) {
          case 401:
            processCallbackError(401, res.text(), callbackError);
            return null;
          case 404:
            processCallbackError(404, res.text(), callbackError);
            return null;
          case 500:
            processCallbackError(500, res.text(), callbackError);
            return null;
          case 200:
            return res.json();
          case 204:
            return null;
          default:
            return res.json();
        }
      })
      .then(function (res) {
        if (res !== null) {
          try {
            if (typeof callback === "function") callback(res);
          } catch (error) {
            console.log(res);
            console.log(error);
          }
        }

      })
      .catch(function (err) {
        console.log(err);
        try {
          if (typeof callbackError === "function") callbackError(err);
          else {
            processCallbackError(null, err);
          }
        } catch (error) {
          console.log(error);
        }
      });
  };


  /* ########################### */
  /* ASYNC AWAIT METHODS
  /* ########################### */

  // API (GRAPH QL)
  var getApiAsync = async function (graphQuery, method, url, releasePath) {
    var query = {
      query: graphQuery
    };
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var graphRequest = {
      method: method ? method : "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(query)
    };
    return await processApiRequestAsync(graphRequest, url, releasePath);
  };

  var processApiRequestAsync = async (graphRequest, url, releasePath) => {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    try {
      const response = await fetch(url ? url : stent.api.api + (releasePath ? "/" + releasePath : ""), graphRequest);
      let jsonResponse = await response.json();
      switch (response.status) {
        case 401:
        case 404:
        case 500:
          return {
            ok: false,
            message: jsonResponse
          };
        case 204:
          return null;
        case 200:
          return {
            ok: true,
            message: jsonResponse
          };
        default:
          return jsonResponse;
      }
    } catch (err) {
      return err;
    }
  };

  // GRAPH QL
  var getGraphQLAsync = async function (graphQuery, releasePath) {
    var query = {
      query: graphQuery
    };
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var graphRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(query)
    };
    return await processGraphQLRequestAsync(graphRequest, releasePath);
  };

  var processGraphQLRequestAsync = async (graphRequest, releasePath) => {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    try {
      const response = await fetch(stent.api.graphQL + (releasePath ? "/" + releasePath : ""), graphRequest);
      let jsonResponse = await response.json();
      switch (response.status) {
        case 401:
        case 404:
        case 500:
          return {
            ok: false,
            message: jsonResponse
          };
        case 204:
          return null;
        case 200:
          return {
            ok: true,
            message: jsonResponse
          };
        default:
          return jsonResponse;
      }
    } catch (err) {
      return err;
    }
  };

  // REST
  const getRestAsync = async (path, forceUrl, forceToken) => {

    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (typeof forceToken !== "undefined" ? forceToken : stent.auth.getBearer()),
      "X-Workspace-ID": stent.tenant.key
    };

    var restApiRequest = {
      method: "GET",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default"
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  const postRestAsync = async (path, body, forceUrl) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  const putRestAsync = async (path, body, forceUrl) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "PUT",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: body ? JSON.stringify(body) : ""
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  const patchRestAsync = async (path, body, forceUrl) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "PATCH",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: body ? JSON.stringify(body) : ""
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  const deleteRestAsync = async (path, body, forceUrl) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + stent.auth.getBearer(),
      "X-Workspace-ID": stent.tenant.key
    };
    var restApiRequest = {
      method: "DELETE",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body)
    };
    return await processRestRequestAsync(path, restApiRequest, forceUrl);
  };

  var processRestRequestAsync = async (path, restApiRequest, forceUrl) => {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    try {
      const response = await fetch((forceUrl ? forceUrl : stent.api.rest) + path, restApiRequest);
      let jsonResponse = await response.json();
      switch (response.status) {
        case 401:
        case 404:
        case 500:
          return {
            ok: false,
            message: jsonResponse
          };
        case 204:
          return null;
        case 200:
          return {
            ok: true,
            message: jsonResponse
          };
        default:
          return jsonResponse;
      }
    } catch (err) {
      return err;
    }
  };

  // AUTH
  const postAuthAsync = async (path, body) => {
    var httpHeaders = {
      "Content-Type": "application/json",
      "X-Workspace-ID": stent.tenant.key
    };
    var authApiRequest = {
      method: "POST",
      headers: new Headers(httpHeaders),
      mode: "cors",
      cache: "default",
      credentials: "include",
      body: JSON.stringify(body)
    };
    return await processAuthRequestAsync(path, authApiRequest);
  };

  var processAuthRequestAsync = async (path, authApiRequest) => {
    // check bearer expires date validity
    if (stent.auth.isBearerExpired() === true) {
      let refreshTokenRequest = await stent.auth.refreshToken();
      if (!refreshTokenRequest.ok || !refreshTokenRequest.message || !refreshTokenRequest.message.refresh_token || !refreshTokenRequest.message.access_token) {
        stent.auth.cleanCookiesAndLocalStorage();
        stent.auth.redirectToAuthPage();
        return;
      }
    }
    try {
      const response = await fetch(stent.api.auth + path, authApiRequest);
      let jsonResponse = await response.json();
      switch (response.status) {
        case 401:
        case 404:
        case 500:
          return {
            ok: false,
            message: jsonResponse
          };
        case 204:
          return null;
        case 200:
          return {
            ok: true,
            message: jsonResponse
          };
        default:
          return jsonResponse;
      }
    } catch (err) {
      return err;
    }
  };

  return {
    getGraphQL,
    getGraphQLAsync,

    getApiAsync,

    getRest,
    getRestAsync,
    putRest,
    putRestAsync,
    postRest,
    postRestAsync,
    deleteRest,
    deleteRestAsync,
    patchRest,
    patchRestAsync,

    postAuthAsync
  };
})();
stent.chooseTenant = function () {

  let userTenants = [];

  const initList = function () {

    let tenantsHTML = "";

    if (userTenants.length > 0) {

      userTenants.forEach(tenant => {
        /* eslint-disable */
       tenantsHTML += `
         <a 
           class="name aTenant" 
           href="${window.location.origin + '/' + tenant._id.replace(/tenants\//gi, '') + '/' + window.location.search}"
           >
           ${tenant.company && tenant.company.logo ?
             `<span class="logo" style="background-image:url('${tenant.company.logo}');"></span>`:
             stent.utils.generateInitialsLogo(tenant.company && tenant.company.name ? tenant.company.name : tenant.host)
           }
           <span class="name dislayName">${tenant.company.name}</span>
         </a>
       `;
       /* eslint-enable */
      });

      $("#tenants-list").html(tenantsHTML);

      //Necessary to get the client side filter feature works
      stent.list.add($("#chooseTenantForm"), {
        valueNames: ["name"]
      });

    }
  };

  const scrollToTenantElement = function (elementIndex) {
    if (typeof elementIndex !== "undefined") {
      $("#tenants-list").animate({
        scrollTop: (elementIndex * 36) - ($("#tenants-list").height() / 2)
      },0);
    }
  };

  const bindKeyboardNavigation = function () {

    // Keycodes =>
    // UP: 38
    // DOWN : 40
    // ENTER : 13
    // ECHAP : 27

    $("body")
      .off("keydown")
      .on("keydown", function (e) {

        // Count elements visible in list
        let $tenantsList = $("#tenants-list .aTenant");
        let countTenants = $tenantsList.length;
        let $selectedTenant = $("#tenants-list .aTenant.selected");
        let currentSelectedIndex = $tenantsList.index($selectedTenant);

        switch (e.keyCode) {

          // Enter key
          case 13:
            if ($selectedTenant.length > 0) {
              let url = $selectedTenant.attr("href");
              if (url !== "") {
                window.location.href = url;
              }

            }
            break;

          // Key down
          case 40:

            $selectedTenant.removeClass("selected");

            // If no tenant is selected, select the first in the list
            if (countTenants > 0) {
              $selectedTenant.removeClass("selected");

              if (currentSelectedIndex === -1) {
                $($tenantsList[0]).addClass("selected");
                scrollToTenantElement(0);
              } else {
                $($tenantsList[currentSelectedIndex + 1]).addClass("selected");
                scrollToTenantElement(currentSelectedIndex + 1);
              }
            }
            break;

          // Key up
          case 38:

            $selectedTenant.removeClass("selected");

            // If no tenant is selected, select the last in the list
            if (countTenants > 0) {
              if (currentSelectedIndex === -1) {
                $($tenantsList[countTenants - 1]).addClass("selected");
                scrollToTenantElement(countTenants - 1);
              } else {
                $($tenantsList[currentSelectedIndex - 1]).addClass("selected");
                scrollToTenantElement(currentSelectedIndex - 1);
              }
            }
            break;

          // Others keys
          default:
            $selectedTenant.removeClass("selected");
            scrollToTenantElement(0);

            break;
        }

      });

  };

  const init = async function () {

    // Check if the user is connected
    let bearer = stent.auth.getBearer();

    if (!bearer) {
      stent.auth.cleanCookiesAndLocalStorage();
      stent.auth.redirectToAuthPage();
      return;
    }

    let userTenantsRequest = await stent.ajax.getRestAsync("/tenants");

    if (userTenantsRequest.ok && userTenantsRequest.ok === true && userTenantsRequest.message) {

      userTenants = [...userTenantsRequest.message];

      if (userTenants.length === 1) {
        let redirectUrl = window.location.href + `${userTenants[0]._id.replace(/tenants\//gi, "")}`;
        stent.utils.log(redirectUrl);
        window.location = redirectUrl;
      } else {
        setTimeout(
          function () {
            $("#loader").removeClass("visible");
          }, 1000
        );

        initList();
        bindKeyboardNavigation();
      }

    } else {

      $("#chooseTenantForm").html(`
        <div class="alert alert-danger fade show" role="alert">
          There was an error during the get workspaces process. <a style="color: white; text-decoration: underline;" href="./">Retry</a>
        </div>
        `
      );

    }
  };

  return {
    init
  };

}() ;